@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  fontFamily: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  
}
hr{
  margin: 0;
}
a>p{
  color: white;
}
a>svg{
  color: #0A3A22;
}
p>.footerlink{
  color: white !important;
}
.footerlink>svg{
  color: white !important;
}
.MuiTypography-root>a{
  color: black;
}
a {
  text-decoration: none;
}
.MuiButtonGroup-grouped{
  min-width: 10px !important;
}

@media screen and (min-width: 400px) and (max-width: 475px){
  .footertext.MuiBox-root>a>p{
    fontSize: 10px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>p{
    fontSize: 10px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>.MuiGrid-root.MuiGrid-container>svg{
    fontSize: 10px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>.MuiGrid-root.MuiGrid-container>p{
    fontSize: 10px ;
  }
}

@media screen and (min-width: 475px) and (max-width: 600px){
  .footertext.MuiBox-root>a>p{
    fontSize: 12px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>p{
    fontSize: 12px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>.MuiGrid-root.MuiGrid-container>svg{
    fontSize: 12px ;
  }
  .footertext.MuiBox-root>.MuiGrid-root>.MuiGrid-root.MuiGrid-container>p{
    fontSize: 12px ;
  }
}